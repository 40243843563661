@use "../../colors.scss";
@import "~bootstrap/scss/bootstrap";

.lessons {
    padding: 0;
    padding-bottom: 50px;
}

.lessonListItem {
    border-radius: 10px;
    margin: 25px;
    background-color: var(--color-white);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .lessonDetails {
        padding: 10px;
        text-align: left;
        .title {
            font-size: 18px;
            font-weight: 700;
            padding-left: 4px;
        }
        .cards span {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .lockedIcon {
        padding: 0 10px;
    }

    .comingSoon {
        padding-right:15px;
    }

    .lessonActions {
        display: flex;
        gap: 10px;
        padding: 10px;
        flex-direction: row;
        button {
            flex-grow: 1;
            padding: 10px;
        }
    }

    .strengthWrapper {
        flex: 1 1 100%;
        border-radius: 10px;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        .strengthMeter div {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.checkpointListItem {
    margin-bottom: 25px;
    background-color: var(--color-white);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .checkpointDetails {
        padding: 10px 35px;
        text-align: left;
        .title {
            font-size: 18px;
            font-weight: 700;
            padding-left: 4px;
        }
        .lessons-span {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .lockedIcon {
        padding: 0 10px;
        padding-right:35px;
    }

    .checkpointActions {
        display: flex;
        gap: 10px;
        padding: 10px;
        padding-right: 35px;
        flex-direction: row;
        button {
            flex-grow: 1;
            padding: 10px;
        }
    }
}

.fullPracticeListItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .fullPracticeDetails {
        padding: 10px 35px;
        text-align: left;
        .title {
            font-size: 18px;
            font-weight: 700;
            padding-left: 4px;
        }
        .lessons-span {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .lockedIcon {
        padding: 0 10px;
        padding-right:35px;
    }

    .fullPracticeActions {
        display: flex;
        gap: 10px;
        padding-top: 25px;
        padding-right: 25px;
        padding-left: 25px;
        flex-direction: row;
        width: 100%;
        button {
            flex-grow: 1;
            padding: 10px;
        }
    }
}

.locked {
    background-color: var(--color-gray);
}