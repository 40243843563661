@use "../../colors.scss";
@import "~bootstrap/scss/bootstrap";

.welcome {
    padding: 25px;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

.welcomeWrapper {
    border-radius: 10px;
    background-color: var(--color-white);
    padding: 20px;
    text-align: left;
}