.header {
    color: #1A1A1A;
    font-weight: 700;
    padding: 15px;
    background-color: #E2E2E2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .streak, .info {
        width: 80px;
    }
    .info {
        text-align: right;
        svg {
            margin-left: 10px;
        }
    }
    .streak {
        text-align: left;
        svg {
            margin-top: -4px;
        }
    }
    .title {
        flex-grow: 3
    }
}