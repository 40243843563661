@use "./colors.scss";

.App {
	text-align: center;
	max-width: 500px;
	margin: 0 auto;
	background-color: var(--color-black);
	min-height: 100vh;
	position: relative;
}

.body {
	position: relative;
}

.red {
	color: var(--color-red);
	font-weight: 700;
}

.black {
	color: var(--color-black);
	font-weight: 700;
}

.btn-success {
    --bs-btn-bg: var(--color-green) !important;
    --bs-btn-border-color: var(--color-green) !important;
}
