@use "../../colors.scss";
@import "~bootstrap/scss/bootstrap";

.streakReset, .streakIncreased {
    padding: 25px;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

.streakResetWrapper, .streakIncreasedWrapper {
    border-radius: 10px;
    background-color: var(--color-white);
    padding: 20px;
    text-align: center;
    img {
        width: 80%;
        border-radius: 2000px;
        margin-bottom: 20px;
    }
}