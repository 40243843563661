.lesson, .completed {
    background-color: var(--color-white);
    padding: 15px;
    border-radius: 10px;
    min-height: 80vh;
    margin: 25px;

    .progressContainer {
        background-color: var(--color-gray);
        border-radius: 4px;
    }

    .progressCompleted {
        background-color: var(--color-green);
    }
}

.lessonHeader {
    .lessonTitle div {
        font-size: 18px;
        font-weight: 700;
        display: inline-block;
        width: 50%;
        text-align: left;
    }
    .lessonTitle div:nth-child(2) {
        text-align: right;
    }
    .lessonProgress {
        margin-top: 5px;
    }

    margin-bottom: 20px;
}

.completed .lessonHeader .lessonTitle div {
    width: 100% !important;
}

.exerciseButtons {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid var(--color-black);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    img {
        width: 25% !important;
    }
}

.successWrapper, .completedWrapper, .failWrapper {
    padding-bottom: 50px;
    padding-top: 100px;
    .completedText{
        font-size: 20px;
        padding-top: 25px;
        font-weight: 700;
    }
    .resultText{}
    .additionalText{
        font-weight: 700;
        padding-top: 25px;
    }
}